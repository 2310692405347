import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../NavBar.js';
import StoreItem from './storeItem.js';
import StoreItemSized from './storeItemSized.js';
import Cart from './cart.js';
import '../App.css';
import cover from '../res/pencover.png';
import shirt from '../res/shirt.jpg';
import tote from '../res/totebag.jpg';

const BACKEND_ORIGIN = process.env.REACT_APP_BACKEND_ORIGIN || 'localhost:3030';
const PROTOCOL = window.location.protocol;

function Store() {

    // each quantity should have a name, size and quantity
        // name can't be key because then there will be three shirts
        // so there should be no keys...
        // so I need a struct to store an inventory item
        // 

    const [quantities, setQuantities] = useState({
        sketchbook: 0,
        shirt: {
            S: 0,
            M: 0,
            L: 0,
            XL: 0,
        },
        bag: 0,
    });

    const products = {
        sketchbook: 20.00,
        shirtS: 30.00,
        shirtM: 30.00,
        shirtL: 30.00,
        shirtXL: 30.00,
        bag: 12.00
    };

    const priceIDs = {
        sketchbook: "price_1QIy3P2KaUsZb1OgLmHhXOPk",
        shirt: "price_1QIy6F2KaUsZb1OgKrY5h4o8",
        bag: "price_1QIy9L2KaUsZb1Og7C4vXm2M"
    }

    const updateQuantity = (itemName, size = "") => {
        setQuantities((prevQuantities) => {
            if (size) {
                return {
                    ...prevQuantities,
                    [itemName]: {
                        ...prevQuantities[itemName],
                        [size]: (prevQuantities[itemName]?.[size] || 0) + 1,
                    },
                };
            }
            return {
                ...prevQuantities,
                [itemName]: (prevQuantities[itemName] || 0) + 1,
            };
        });
    };

    const totalPrice = Object.entries(quantities).reduce((total, [itemName, quantity]) => {
        if (typeof quantity === 'object') {
            Object.entries(quantity).forEach(([size, sizeQuantity]) => {
                if (sizeQuantity > 0) {
                    const sizeKey = `${itemName}${size}`;
                    const itemPrice = products[sizeKey];
                    total += itemPrice * sizeQuantity;
                }
            });
        } else {
            const itemPrice = products[itemName];
            total += itemPrice * quantity;
        }
        return total;
    }, 0);
    

    console.log(totalPrice);

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const lineItems = [];
        for (let [key, value] of Object.entries(quantities)) {
            if (typeof value === 'object') {
                console.log("skipping: ", key, value);

                for (let [size, quant] of Object.entries(value)) {
                    if (quant > 0) {
                        lineItems.push({ price: priceIDs[key], quantity:quant});

                        try {
                            const response = await fetch(`${PROTOCOL}//${BACKEND_ORIGIN}/decrement-inventory`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({ product_name: key, quantity: quant, size: size})
                            });
            
                            if (!response.ok) {
                                throw new Error(`Failed to decrement inventory for ${key}`);
                            }
                        } catch (error) {
                            console.error('Error decrementing inventory:', error);
                        }

                    }

                    
                }
            }
            else if (value > 0) {
                lineItems.push({ price: priceIDs[key], quantity: value });
    
                // Call the decrement endpoint for each item
                try {
                    const response = await fetch(`${PROTOCOL}//${BACKEND_ORIGIN}/decrement-inventory`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ product_name: key, quantity: value})
                    });
    
                    if (!response.ok) {
                        throw new Error(`Failed to decrement inventory for ${key}`);
                    }
                } catch (error) {
                    console.error('Error decrementing inventory:', error);
                }
            }
        }
    
        if (lineItems.length > 0) {
            navigate('/checkout', { state: { lineItems } });
        }
    };

    const handleQuantityDecrement = (itemName, size = "") => {
        setQuantities((prevQuantities) => {
            if (typeof prevQuantities[itemName] === 'object') {
                if (size && prevQuantities[itemName][size] > 0) {
                    return {
                        ...prevQuantities,
                        [itemName]: {
                            ...prevQuantities[itemName],
                            [size]: prevQuantities[itemName][size] - 1,
                        },
                    };
                }
            } else {
                if (prevQuantities[itemName] > 0) {
                    return {
                        ...prevQuantities,
                        [itemName]: prevQuantities[itemName] - 1,
                    };
                }
            }
            return prevQuantities;
        });
    };
    

    return (
        <div>
            <NavBar/>

            <div id="storePage">
                <h1>WELCOME TO MY STORE</h1> <br></br>
                {/* add store grid here */}
                <div className="storeItemsContainer">
                    <StoreItem
                        img={cover}
                        name="sketchbook"
                        price="20.00"
                        onQuantityChange={(newQuantity) => updateQuantity("sketchbook")}
                    />
                    <StoreItemSized
                        img={shirt}
                        name="shirt"
                        price="30.00"
                        onQuantityChange={(size) => updateQuantity("shirt", size)}
                    />
                    <StoreItem
                        img={tote}
                        name="bag"
                        price="12.00"
                        size={false}
                        onQuantityChange={(newQuantity) => updateQuantity("bag")}
                    />
                </div>
                <Cart 
                    quantities={quantities} 
                    products={products} 
                    totalPrice={totalPrice}
                    onQuantityDecrement={handleQuantityDecrement}/>
            
                <form onSubmit={handleSubmit}>
                    <input type="submit" value="Continue"></input>
                </form>
            </div>
            
        </div>
    );
}

export default Store;